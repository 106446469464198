<template>
  <div class="login">
    <vue-element-loading :active="qrLoading" text="登录成功，跳转中..." color="#00cca2" spinner="spinner"/>
    <a-form class="user-layout-login" ref="formLogin" :form="form">
      <a-tabs
        :activeKey="tabActive"
        :tabBarStyle="{ textAlign: 'center', borderBottom: 'unset' }"
        @change="handleTabClick">
        <a-tab-pane key="1" tab="密码登录 ">
          <a-form-item>
            <a-input size="large" type="text" placeholder="手机号码"
              v-decorator="rules.username" @keydown="submitForm($event)">
              <a-icon slot="prefix" type="user" />
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-input size="large" type="password" placeholder="登录密码"
              v-decorator="rules.password" @keydown="submitForm($event)">
              <a-icon slot="prefix" type="lock" />
            </a-input>
          </a-form-item>
          <!-- <a-form-item>
            <a-checkbox @change="toRember" :checked='isChecked'>记住我</a-checkbox>
            <router-link :to="'/'" class="forge-password">忘记密码</router-link> 
          </a-form-item> -->
          <a-form-item>
            <a-button size="large" type="primary" class="login-button" @click="doLogin" :loading='loading'>登录</a-button>
          </a-form-item>

          <a-form-item>
            <a href="https://next.wedots.cn/v2b/wxwork/qr-login" style="align:center;">
              <img style="width:100%" src="//wwcdn.weixin.qq.com/node/wwopen/wwopenmng/style/images/independent/brand/300x40_white$4dab5411.png" srcset="//wwcdn.weixin.qq.com/node/wwopen/wwopenmng/style/images/independent/brand/300x40_white_2x$6a1f5234.png 2x" alt="企业微信登录">
            </a>
          </a-form-item>
        </a-tab-pane>
        <!-- <a-tab-pane key="2" tab="扫码登录">
          <div class="qrcode-tips">请使用微信扫描小程序码登录微点云助教</div>
          <a-avatar shape="square" :src="loginQrcode.img" :size="200" class="qrcode"/>
          <a-spin v-if="!stepCode" class="erweima erweimaLoading" size="large" />
        </a-tab-pane> -->
        <a-tab-pane key="3" tab="人脸登录">
            <div class="qrcode-tips" style="font-size:14px;">
              请注视镜头并保持一段时间。
            </div>
            <div id="video-background" class="bk-radius">
              <video v-show="true" id="video" class="video-camera" preload autoplay loop muted />
              <canvas id="overlay"></canvas>
            </div>
            <div id="result" style="position:absolute;top:10000rpx;">
              <canvas v-show="flagCanvas === true" id="canvas1" width="200" height="200"/>
            </div>
        </a-tab-pane>
      </a-tabs>
    </a-form>
  </div>
</template>

<script>
require("tracking/build/tracking-min.js");
require("tracking/build/data/face-min.js");
import dat from "dat.gui";
import * as faceapi from "face-api.js";
export default {
  name: 'Login',
  data() {
    return {
      loading: false,
      qrLoading: false,
      form: this.$form.createForm(this),
      tabActive: '1',
      stepCode: '',

      loginQrcode: {},
      timer: null,
      isChecked:false,
      rules: {
        username: [ 'username',
          {rules: [{ required: true, message: '请输入手机号!' }]}
        ],
        password: [ 'password',
          {rules: [{ required: true, message: '请输入登录密码!' }]}
        ]
      },
      trackerTask: "",
      tracker: {},
      tracker2: {},
      mediaStreamTrack: {},
      video: {},
      count: 0,
      flag: true,
      flagCanvas: false,
      flagCanvas2: false,
      base64Str: "",
      clock: "",
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    }
  },
  created() {
    this.tabActive = this.$route.meta.index
    if(this.$route.meta.index === '2'){
      this.getLoginQrcode()
    }
    if(this.$route.query.auth_code){
      this.qrLoading = true
      this.wxworkQrLogin(this.$route.query)
    }
  },
  mounted(){
    this.$nextTick(()=>{
      if(this.$ls.get('isChecked')){
        this.isChecked = true
        let obj = {
          username:this.$ls.get('username'),
          password:this.$ls.get('password')
        }
        this.form.setFieldsValue(obj)
      }
    })
  },
  methods: {
     async resetTracking() {
      const _this = this;
      this.flag = true;
      this.flagCanvas = false;
      this.flagCanvas2 = false;
      this.base64Str = "";
      this.trackerTask = "";
      this.tracker = {};
      this.tracker2 = {};
      this.mediaStreamTrack = {};
      this.video = {};
      this.count = 0;
      this.x = 0;
      this.y = 0;
      this.width = 0;
      this.height = 0;
      
      this.$nextTick(()=>{
        this.initTracking();
      })
    },
    // 初始化人脸追踪
    async initTracking() {
      //
      const videoBackground = document.getElementById("video-background");
      if(!videoBackground)
        return ;
      videoBackground.style.backgroundImage = "";
      const _this = this;
      const video = (this.video = document.getElementById("video"));
      var canvas = document.getElementById("overlay");
      var context = canvas.getContext("2d");

      _this.tracker = new window.tracking.ObjectTracker("face");
      _this.tracker.setInitialScale(4);
      _this.tracker.setStepSize(2);
      // 转头角度影响识别率
      _this.tracker.setEdgesDensity(0.1);

      _this.openCamera();
      _this.trackerTask = window.tracking.track("#video", _this.tracker);

      // 使用期约来识别人脸，识别有就提示用户保持静止，3秒后拍照
      let prom = new Promise((resolve, reject) => {
        setTimeout(() => {
          _this.tracker.on("track", function (event) {
            if (event.data.length) {
              // 会不停的去检测人脸，所以这里需要做个锁
              if (_this.flag) {
                _this.flag = false;
                _this.$message.success('已识别人脸，请注视镜头并保持一段时间');
                _this.base64Str = "";
                setTimeout(() => {
                  _this.flagCanvas = true;
                  const canvasUpload = document.getElementById("canvas1");
                  if(!canvasUpload)
                    return ;
                  const contextUpload = canvasUpload.getContext("2d");
                  contextUpload.clearRect(0, 0, canvasUpload.width, canvasUpload.height);
                  contextUpload.drawImage(video, 90, 30, 400, 400, 0, 0, 200, 200);
                  _this.base64Str = canvasUpload.toDataURL("image/jpeg");
                  _this.video.pause();
                  if (_this.base64Str) {
                    //console.log(_this.base64Str);
                    _this.$message.success('捕获人脸成功');
                    _this.faceLogin(_this.base64Str)

                  } else {
                    _this.$message.warning('未捕获人脸，请重新拍照'); 
                  }
                  // 关闭摄像头
                  // _this.closeCamera();
                  // 直接获取 base64 不显示画布可开启
                  // _this.flag = true;
                }, 2500);
              }
            }
          });
          resolve();
        }, 0);
      });
      prom.then(() => {
        setTimeout(() => {
          _this.resetTracking();
        }, 9000);
      });
      // await sleep(2000);
    },

    openCamera() {
      if(this.tabActive!=3){
        return ;
      }
      console.log('open');
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先，如果有getUserMedia的话，就获得它
          const getUserMedia =
            navigator.getUserMedia ||
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia;
          // 一些浏览器根本没实现它 - 那么就返回一个error到promise的reject来保持一个统一的接口
          if (!getUserMedia) {
            return Promise.reject(new Error("getUserMedia 浏览器不支持摄像头"));
          }
          // 否则，为老的navigator.getUserMedia方法包裹一个Promise
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }
      const constraints = {
        video: true,
        audio: false,
      };
      const promise = navigator.mediaDevices.getUserMedia(constraints);
      promise
        .then((stream) => {
          this.mediaStreamTrack = stream.getTracks()[0];
          window.stream = stream;
          const video = document.getElementById("video");
          // 旧的浏览器可能没有srcObject
          if ("srcObject" in video) {
            video.srcObject = stream;
          } else {
            video.src = window.URL.createObjectURL(stream);
          }
          video.onloadedmetadata = function (e) {
            video.play();
          };
        })
        .catch((err) => {
          console.error(err.name + ": " + err.message);
          console.error("获取权限失败");
        });
    },
    closeCamera() {
      console.log('close');
      if (typeof window.stream === "object") {
        if ("srcObject" in this.video) {
          this.video.srcObject = null;
        }
        window.stream.getTracks().forEach((track) => track.stop());
      }
    },
    handleTabClick (key) {
      this.tabActive = key
      clearInterval(this.timer)
      if (key === '2') {
        this.getLoginQrcode()
      }
      if (key === '3') {
        this.resetTracking();
      }else{
        this.closeCamera();
      }
    },
    async doLogin () {
      try {
        let params = await this.form.validateFields()
        if(this.isChecked){
          this.$ls.set('username',params.username)
          this.$ls.set('password',params.password)
          this.$ls.set('isChecked',1)
        }
        if (params) {
          this.loading = true
          await this.$store.dispatch('loginAction', params)
            .then(res=>{
              this.loading = false
              this.$router.push('/')
            })
            .catch(err=>{
              if(err === 1){
                this.$router.push({path:'/404',query:{auth:1}})
              }
              this.loading = false
            })
          
        }
      } catch {
      }
			this.confirmLoading=false;
    },
    async getLoginQrcode () {
      let res = await this.$store.dispatch('loginQrcodeAction', {params: {}})
      this.loginQrcode = res.data
      this.listenCodeLogin()
    },
    // ceshi
    listenCodeLogin() {
      let { uuid } = this.loginQrcode
      this.timer = setInterval(async () => {
        let res = await this.$store.dispatch('qrLoginAction', {data: {uuid}, hideLoading: true})
        if (res && res.access_token) {
          this.$router.push('/work/dashboard')
          clearInterval(this.timer)
        }
      }, 5000)
    },
    submitForm(e) {
      if (e.keyCode === 13) {
        this.doLogin()
      }
    },
    async toRember(e){
      this.isChecked = !this.isChecked
      if(!this.isChecked){
        this.$ls.remove('username')
        this.$ls.remove('password')
        this.$ls.remove('isChecked')
      }
    },
    async faceLogin (base64Str) {
      let res = await this.$store.dispatch('faceLoginAction',{face:base64Str})
      if(res.data.status=='success'){
        this.closeCamera();  
        this.$router.push('/')
      }else{
          this.$message.error('未匹配到相符人脸。'); 
      }
      // this.loginQrcode = res.data
      // this.listenCodeLogin()
    },
    async wxworkQrLogin(obj){
      await this.$store.dispatch('wxworkQrLoginAction',{params:obj})
      .then(res=>{
        this.$router.push('/')
      })
      .catch(err=>{
        if(err === 1){
          this.$router.push({path:'/404',query:{auth:1}})
        }
        this.qrLoading = false
      })
    }
  }
}
</script>
<style scoped>
.bk-radius {
  width: 200px;
  height: 200px;
  overflow: hidden;
  /* background-color: #42b983; */
  background-image: url("../../../assets/face.png");
  z-index: -1;
  /* opacity: 0.5; */
  border-radius: 20%;
  margin: auto;
}

.rect2 {
  border: 2px solid #a64ceb;
  left: -1000px;
  position: absolute;
  top: -1000px;
}
.video-camera {
  width: 300px;
  height: 300px;
  margin-top: -40px;
  margin-left: -40px;

  /* position: absolute; */
}

.custom-block.tip {
  background-color: #ecf8ff;
  border-color: #50bfff;
  color: #409eff;
}

.custom-block,
.custom-block.tip,
.custom-block {
  padding: 0.1rem 1.5rem;
  border-left-width: 0.5rem;
  border-left-style: solid;
  margin: 1rem 0;
}
</style>
